import HomeSection from "../../components/HomeSection/HomeSection";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";

const PrivatePolicy = () => {
    return (
        <LayoutWrapper>
            <div className="container">
                <HomeSection header="footer.privatePolicy">
                    <h2>مقدمة</h2>
                    <ol>
                        <li className="fs-4">إننا نهتم بشكل كبير بخصوصية زوار موقعنا، ونتعهد بحمايتها. تشرح هذه السياسة كيفية التصرف في معلوماتك الشخصية.</li>
                    </ol>
                    <h2>جمع المعلومات الشخصية</h2>
                    <ol>
                        <li className="fs-4">قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية: معلومات حول جهاز الكمبيوتر الخاص بك بما في ذلك عنوان بروتوكول الإنترنت الخاص بك، والموقع الجغرافي، ونوع المتصفح والإصدار، ونظام التشغيل.</li>
                        <li className="fs-4">معلومات حول زياراتك لهذا الموقع واستخدامه بما في ذلك مصدر الإحالة، ومدة الزيارة، وما تشاهده على الصفحة، ومسارات التصفح في الموقع.</li>
                        <li className="fs-4">المعلومات التي تدخلها عند التسجيل في موقعنا الإلكتروني، مثل عنوان بريدك الإلكتروني.</li>
                        <li className="fs-4">المعلومات التي تدخلها عند إنشاء ملف تعريف على موقعنا – على سبيل المثال، اسمك، صور ملفك الشخصي، الجنس، تاريخ الميلاد، الحالة الاجتماعية، الاهتمامات والهوايات، الحالة التعليمية، وتفاصيل الوظيفة.</li>
                        <li className="fs-4">المعلومات، مثل اسمك وعنوان بريدك الإلكتروني، التي تدخلها من أجل إعداد اشتراكات </li>
                        <li className="fs-4">المعلومات التي تدخلها أثناء استخدام الخدمات على موقعنا.</li>
                        <li className="fs-4">أي معلومات شخصية أخرى ترسلها إلينا.</li>
                    </ol>

                    <h2>استخدام معلوماتك الشخصية</h2>
                    <ol>
                        <li className="fs-4">إدارة موقعنا وأعمالنا على شبكة الإنترنت.</li>
                        <li className="fs-4">تخصيص موقعنا على شبكة الإنترنت ليكون أكثر ملاءمة بالنسبة لك.</li>
                        <li className="fs-4">تمكينك من استخدام الخدمات المتاحة على موقعنا.</li>
                        <li className="fs-4">حماية موقعنا من الاحتيال والحفاظ على أمنه.</li>
                        <li className="fs-4">التحقق من الامتثال للشروط والأحكام التي تحكم استخدام موقعنا الإلكتروني (بما في ذلك مراقبة الرسائل الخاصة المرسلة عبر خدمة الرسائل الخاصة بموقعنا).</li>
                        <li className="fs-4">استخدامات أخرى.</li>
                    </ol>

                    <h2>الكشف عن المعلومات الشخصية</h2>
                    <p className="fs-4">يمكن أن نقوم بالكشف عن معلوماتك الشخصية لأي من موظفينا أو مسؤولينا أو شركات التأمين أو المستشارين المحترفين أو الوكلاء أو الموردين أو المقاولين من الباطن التابعين لنا القدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.</p>
                    <p className="fs-4">قد نقوم بالإفصاح عن معلوماتك الشخصية لأي عضو في مجموعة شركاتنا (وهذا يعني الشركات التابعة لنا، والشركة القابضة الأصلية وجميع فروعها) بالقدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.</p>
                    <p className="fs-4">قد نكشف عن معلوماتك الشخصية:</p>
                    <ol>
                        <li className="fs-4">القدر الذي يطلب منا القيام به بموجب القانون.</li>
                        <li className="fs-4">فيما يتعلق بأي إجراءات قانونية جارية أو محتملة.</li>
                        <li className="fs-4">من أجل إنشاء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في ذلك توفير معلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر الائتمان).</li>
                        <li className="fs-4">إلى المشتري (أو المشتري المحتمل) لأي عمل أو أصل نبيعه (أو ننوي ذلك).</li>
                        <li className="fs-4">أي شخص نعتقد على نحو معقول أنه قد يتقدم بطلب إلى محكمة أو سلطة مختصة أخرىبطلب الإفصاح عن تلك المعلومات الشخصية، وفقًا لتقديرنا المعقول، ويكون من المحتمل بشكل معقول أن تأمر هذه المحكمة أو السلطة بالكشف عن تلك المعلومات الشخصية.
                            باستثناء ما هو منصوص عليه في هذه السياسة، فإننا لن نقدم معلوماتك الشخصية إلى أطراف ثالثة.
                        </li>
                    </ol>

                    <h2>النقل الدولي للبيانات</h2>
                    <ol>
                        <li className="fs-4">قد يتم تخزين المعلومات التي نجمعها أو معالجتها أو نقلها بين أي من البلدان التي نعمل فيها لتمكيننا من استخدام المعلومات وفقًا لهذه السياسة.</li>
                        <li className="fs-4">قد يتم نقل المعلومات التي نجمعها إلى البلدان التالية والتي لا تخضع لقوانين حماية البيانات المكافئة لتلك المعمول بها في المنطقة الاقتصادية الأوروبية: وهذه الدول هي الولايات المتحدة الأمريكية، روسيا، اليابان، الصين، والهند.</li>
                        <li className="fs-4">لا نستطيع منع استخدام الآخرين أو إساءة استخدامهم للمعلومات الشخصية التي تنشرها على موقعنا الإلكتروني أو ترسلها للنشر على موقعنا الإلكتروني والمتاحة عبر الإنترنت في جميع أنحاء العالم.</li>
                        <li className="fs-4">أنت توافق صراحة على عمليات نقل المعلومات الشخصية الموضحة في هذا القسم “و”.</li>
                    </ol>

                    <h2>أمن معلوماتك الشخصية</h2>
                    <ol>
                        <li className="fs-4">أمن معلوماتك الشخصية  نتعهد باتخاذ الاحتياطات التقنية والتنظيمية المعقولة لمنع فقدان معلوماتك الشخصية أو إساءة استخدامها أو تغييرها.</li>
                        <li className="fs-4">نتعهد بتخزين جميع المعلومات الشخصية التي تقدمها على خوادمنا الآمنة (المحمية بكلمة مرور وجدار الحماية).</li>
                        <li className="fs-4">تقرّ بموافقتك على هذه السياسة بمعرفتك بأن نقل المعلومات عبر الإنترنت غير آمن بطبيعته، ولا يمكننا ضمان أمن البيانات المرسلة عبر الإنترنت.</li>
                        <li className="fs-4">أنت مسؤول عن الحفاظ على سرية كلمة المرور التي تستخدمها للوصول إلى موقعنا. لن نطلب منك كلمة المرور الخاصة بك (إلا عند تسجيل الدخول إلى موقعنا).</li>
                    </ol>

                </HomeSection>
            </div>
        </LayoutWrapper>
    )
}

export default PrivatePolicy;